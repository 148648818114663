import { template } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
export default class ParticipantsByStatusLegend extends Component {
    @service
    elementHelper;
    constructor(...args1){
        super(...args1);
        this.canvasId = 'canvas-' + guidFor(this);
        this.elementHelper.waitForElement(this.canvasId).then((element1)=>{
            element1.width = 14;
            element1.height = 14;
            const ctx1 = element1.getContext('2d');
            ctx1.fillStyle = this.args.dataset.canvas;
            ctx1.fillRect(0, 0, 14, 14);
        });
    }
    static{
        template(`
    <canvas id={{this.canvasId}} class="participants-by-status__legend-view" />
    <span>{{@dataset.legend}}</span>
    <PixTooltip @id="legend-tooltip-{{@dataset.key}}" @isWide="true" @position="top-left">
      <:triggerElement>
        <PixIcon
          @name="help"
          @plainIcon={{true}}
          class="participants-by-status__legend-tooltip"
          tabindex="0"
          aria-describedby="legend-tooltip-{{@dataset.key}}"
        />
      </:triggerElement>
      <:tooltip>
        {{@dataset.legendTooltip}}
      </:tooltip>
    </PixTooltip>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
