import { template } from "@ember/template-compiler";
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import ENV from 'pix-orga/config/environment';
export default class InformationBanner extends Component {
    @service
    currentUser;
    @service
    router;
    @service
    dayjs;
    get displayCertificationBanner() {
        const timeToDisplay1 = ENV.APP.CERTIFICATION_BANNER_DISPLAY_DATES.split(' ');
        const actualMonth1 = this.dayjs.self().format('MM');
        return this.currentUser.isSCOManagingStudents && timeToDisplay1.includes(actualMonth1);
    }
    get year() {
        return this.dayjs.self().format('YYYY');
    }
    static{
        template(`
    {{#if this.displayCertificationBanner}}
      <PixNotificationAlert @type="warning" @withIcon={{true}}>
        {{t
          "banners.certification.message"
          documentationLink="https://cloud.pix.fr/s/DEarDXyxFxM78ps"
          linkClasses="link link--banner link--bold link--underlined"
          htmlSafe=true
          year=this.year
        }}
      </PixNotificationAlert>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
