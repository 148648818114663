import { template } from "@ember/template-compiler";
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import PixStructureSwitcher from '@1024pix/pix-ui/components/pix-structure-switcher';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
export default class UserLoggedMenu extends Component {
    @service
    currentUser;
    @service
    router;
    @service
    store;
    get organizationNameAndExternalId() {
        const organization1 = this.currentUser.organization;
        if (organization1.externalId) {
            return `${organization1.name} (${organization1.externalId})`;
        }
        return organization1.name;
    }
    get eligibleOrganizations() {
        const memberships1 = this.currentUser.memberships;
        if (!memberships1) {
            return [];
        }
        return memberships1.slice().map((membership1)=>{
            let label1 = `${membership1.organization.get('name')}`;
            if (membership1.organization.get('externalId')) {
                label1 = label1.concat(` (${membership1.organization.get('externalId')})`);
            }
            return {
                label: label1,
                value: membership1.organization.get('id')
            };
        }).sort((a1, b1)=>a1.label.localeCompare(b1.label));
    }
    get belongsToSeveralOrganizations() {
        return this.eligibleOrganizations.length > 1;
    }
    @action
    async onOrganizationChange(organization1) {
        const prescriber1 = this.currentUser.prescriber;
        const userOrgaSettingsId1 = prescriber1.userOrgaSettings.get('id');
        const userOrgaSettings1 = await this.store.peekRecord('user-orga-setting', userOrgaSettingsId1);
        const selectedOrganization1 = await this.store.peekRecord('organization', organization1.value);
        userOrgaSettings1.organization = selectedOrganization1;
        await userOrgaSettings1.save({
            adapterOptions: {
                userId: prescriber1.id
            }
        });
        const queryParams1 = {};
        Object.keys(this.router.currentRoute.queryParams).forEach((key1)=>(queryParams1[key1] = undefined));
        this.router.replaceWith('authenticated', {
            queryParams: queryParams1
        });
        await this.currentUser.load();
        this.args.onChangeOrganization();
    }
    static{
        template(`
    <p>
      <strong>
        {{this.currentUser.prescriber.firstName}}
        {{this.currentUser.prescriber.lastName}}
      </strong>
      <br />
      {{this.organizationNameAndExternalId}}
    </p>
    {{#if this.belongsToSeveralOrganizations}}
      <PixStructureSwitcher
        @value={{this.currentUser.organization.id}}
        @structures={{this.eligibleOrganizations}}
        @label={{t "navigation.user-logged-menu.button"}}
        @onChange={{this.onOrganizationChange}}
      />
    {{/if}}
    <PixButtonLink @variant="tertiary" class="" @route="logout">{{t
        "navigation.user-logged-menu.logout"
      }}</PixButtonLink>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
